import Mention from "@tiptap/extension-mention";
import { Range } from "@tiptap/react";
import { makeInsertFn } from "./makeInsertFn";

declare module "@tiptap/core" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Commands<ReturnType> {
    mentionFn: {
      insertFn: (text: string, range?: Range) => ReturnType;
    };
  }
}

const START_REGEX = /^{%(\s)?/g;
const END_REGEX = /(\s)?%}$/g;

export const MentionFnBase = Mention.extend({
  name: "mention-fn",
  addCommands() {
    return {
      insertFn: makeInsertFn(this.type, START_REGEX, END_REGEX),
    };
  },
});
