import { reverse, sortBy, uniqBy } from "lodash-es";
import { useMemo, useState } from "react";
import { UserJob } from "src/types";
import { apiUrl } from "./api";
import { getToken } from "./auth";
import { useAsyncEffect } from "./hooks/useAsyncEffect";

const SOCKET_URL = apiUrl("/cable");

export const makeConsumer = async (token: string) => {
  const { createConsumer } = await import("@rails/actioncable");
  return createConsumer(`${SOCKET_URL}?token=${token}`);
};

type ChannelConnectionCallbacks<T extends unknown = unknown> = {
  connected?: () => void;
  disconnected?: () => void;
  received?: (data: T) => void;
};

export const useChannel = <T>(
  channel: string,
  params?: Record<string, any>,
  callbacks?: ChannelConnectionCallbacks<T>,
) => {
  const token = getToken();

  useAsyncEffect(
    async () => {
      if (!token) {
        return;
      }

      const socketConsumer = await makeConsumer(token);

      if (channel && socketConsumer) {
        socketConsumer.subscriptions.create({ channel, ...params }, callbacks);
      }

      return socketConsumer;
    },
    [channel, params, token],
    (socketConsumer) => {
      if (socketConsumer) {
        socketConsumer.disconnect();
      }
    },
  );
};

export const useEntitySubscription = <T>(
  entity: string,
  id: string,
  onReceive: (data: T) => void,
) => {
  const params = useMemo(() => ({ entity: `${entity}:${id}` }), [entity, id]);

  const callbacks = useMemo(() => ({ received: onReceive }), [onReceive]);

  return useChannel("UpdatesChannel", params, callbacks);
};

export const useUserJobsSubscription = (context?: string) => {
  const [userJobs, setUserJobs] = useState<UserJob[]>([]);
  const callbacks = useMemo(
    () => ({
      received(data: UserJob) {
        setUserJobs((old) => uniqBy([data, ...old], "id"));
      },
      connected() {
        // @ts-expect-error: The typing here is wrong, this method does exist
        this.perform("start_listening");
      },
    }),
    [],
  );

  const params = useMemo(() => ({ context }), [context]);

  const sortedUserJobs = useMemo(
    () => reverse(sortBy(userJobs, (j) => [!j.completed, j.completedAt])),
    [userJobs],
  );

  useChannel("UserJobsChannel", params, callbacks);

  return sortedUserJobs;
};
