export const unTrailingSlashIt = (str: string): string => {
  if (str.endsWith("/") || str.endsWith("\\")) {
    return unTrailingSlashIt(str.slice(0, -1));
  }

  return str;
};

export const trailingSlashIt = (str: string): string => {
  return unTrailingSlashIt(str) + "/";
};
