import { Alert, Button, LoadingGate, PageContainer } from "@ream/ui";

import clsx from "clsx";
import React from "react";
import { useAuth } from "src/state/AuthContext";
import { BasePageLayout } from "./BasePageLayout";
import { SidebarNav } from "./nav/SidebarNav";

type Props = {
  title?: string;
  loading?: boolean;
  flush?: boolean;
  children: React.ReactNode;
  hideSidebar?: boolean;
  contained?: boolean;
};

// TODO: App Router, make these layouts (all in here) part of the app router layout.tsx setup

const ImpersonationAlert = () => {
  const { user, trueUser, stopImpersonatingUser } = useAuth();

  return (
    <Alert flush variant="warning">
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <p className="mb-0">
          <strong>Impersonation in Progress.</strong>{" "}
          <small>
            You are impersonating <em>{user?.email}</em> from{" "}
            <em>{trueUser?.email}</em>.
          </small>
        </p>
        <Button
          variant="warning"
          size="sm"
          onClick={stopImpersonatingUser}
          className="ms-auto"
        >
          Stop Impersonation
        </Button>
      </div>
    </Alert>
  );
};

export const AppPageLayout: React.FC<Props> = ({
  title,
  flush = false,
  loading = false,
  children,
  hideSidebar = false,
  contained = false,
}) => {
  const Cont = contained ? PageContainer : React.Fragment;
  const { isImpersonating } = useAuth();

  return (
    <BasePageLayout title={title}>
      <div className="app">
        <div className="app__alert">
          {isImpersonating && <ImpersonationAlert />}
        </div>

        {!hideSidebar && (
          <aside className="app__sidebar">
            <SidebarNav />
          </aside>
        )}

        <main className={clsx("app__content", flush && "app__content--flush")}>
          <Cont>
            <LoadingGate loading={loading}>{children}</LoadingGate>
          </Cont>
        </main>
      </div>
    </BasePageLayout>
  );
};
