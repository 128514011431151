import { QueryClient } from "@tanstack/react-query";
import {
  Account,
  Comment,
  DocumentReview,
  DocumentReviewListing,
  PendingOperation,
  ReviewTask,
  ReviewTaskStatus,
} from "src/types";
import {
  DOC_REVIEW_KEYS,
  DocumentReviewApiDetail,
  DocumentReviewsApiIndex,
} from "../api/documentReviewsApi";
import { exhaustiveGuard } from "../utils";
import {
  applyPendingOperations,
  beginQueryClientMutation,
  resolveQueryClientMutation,
} from "./pending";

///
/// Types
///

export type PendingDocumentReviewOperation =
  | PendingDocumentReviewAddComment
  | PendingDocumentReviewAddCommentReply
  | PendingDocumentReviewUpdateComment
  | PendingDocumentReviewDestroyComment
  | PendingDocumentReviewCreateTask
  | PendingDocumentReviewUpdateTask
  | PendingDocumentReviewDestroyTask;

export type PendingDocumentReviewAddComment = PendingOperation & {
  type: "add_comment";
  content: string;
  account: Account;
};

export type PendingDocumentReviewAddCommentReply = PendingOperation & {
  type: "add_comment_reply";
  content: string;
  account: Account;
  parentId: string;
};

export type PendingDocumentReviewUpdateReviewers = PendingOperation & {
  type: "update_reviewers";
  content: string;
  account: Account;
  parentId: string;
};

export type PendingDocumentReviewUpdateComment = PendingOperation & {
  type: "update_comment";
  content: string;
  commentId: string;
};

export type PendingDocumentReviewDestroyComment = PendingOperation & {
  type: "destroy_comment";
  commentId: string;
};

export type PendingDocumentReviewCreateTask = PendingOperation & {
  type: "create_task";
  title: string;
};

export type PendingDocumentReviewUpdateTask = PendingOperation & {
  type: "update_task";
  taskId: string;
  title?: string;
  status?: ReviewTaskStatus;
};

export type PendingDocumentReviewDestroyTask = PendingOperation & {
  type: "destroy_task";
  taskId: string;
};

///
/// Apply
///

export const applyLocalOperations = (review: DocumentReview | undefined) => {
  return applyPendingOperations(
    review,
    review?._pending ?? [],
    applyPendingDocumentReviewOperation,
  );
};

export const applyPendingDocumentReviewOperation = (
  review: DocumentReview,
  op: PendingDocumentReviewOperation,
) => {
  const type = op.type;
  switch (type) {
    case "add_comment":
      return applyPendingAddComment(review, op);
    case "add_comment_reply":
      return applyPendingAddCommentReply(review, op);
    case "update_comment":
      return applyPendingUpdateComment(review, op);
    case "destroy_comment":
      return applyPendingDestroyComment(review, op);
    case "create_task":
      return applyPendingCreateTask(review, op);
    case "update_task":
      return applyPendingUpdateTask(review, op);
    case "destroy_task":
      return applyPendingDestroyTask(review, op);
    default:
      return exhaustiveGuard(type);
  }
};

export const applyPendingAddComment = (
  review: DocumentReview,
  operation: PendingDocumentReviewAddComment,
) => {
  review.comments.push({
    publicUid: operation.mutationId,
    commenter: {
      ...operation.account,
      personType:
        review.userRelationship === "contact" ? "external" : "internal",
    },
    content: operation.content,
    commentableId: review.publicUid,
    children: [],
    resolved: false,
    edited: false,
    canEdit: true,
    canDelete: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
};

export const applyPendingAddCommentReply = (
  review: DocumentReview,
  operation: PendingDocumentReviewAddCommentReply,
) => {
  const parent = review.comments.find(
    (c) => c.publicUid === operation.parentId,
  );
  if (parent) {
    const children = parent.children ?? [];
    children.push({
      publicUid: operation.mutationId,
      commenter: {
        ...operation.account,
        personType:
          review.userRelationship === "contact" ? "external" : "internal",
      },
      content: operation.content,
      children: [],
      commentableId: review.publicUid,
      resolved: false,
      edited: false,
      canEdit: true,
      canDelete: true,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });
    parent.children = children;
  }
};

export const applyPendingUpdateComment = (
  review: DocumentReview,
  op: PendingDocumentReviewUpdateComment,
) => {
  // search the entire comment tree for the edited comment
  const comment = findComment(review, op.commentId);
  if (comment) {
    comment.content = op.content;
  }
};

export const applyPendingDestroyComment = (
  review: DocumentReview,
  op: PendingDocumentReviewDestroyComment,
) => {
  review.comments.forEach((oldComment) => {
    if (oldComment.publicUid === op.commentId) {
      oldComment._deleting = true;
    }

    oldComment.children?.forEach((child) => {
      if (child.publicUid === op.commentId) {
        child._deleting = true;
      }
    });
  });
};

export const applyPendingCreateTask = (
  review: DocumentReview,
  op: PendingDocumentReviewCreateTask,
) => {
  review.tasks.push({
    publicUid: op.mutationId,
    status: "open",
    title: op.title,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
};

export const applyPendingUpdateTask = (
  review: DocumentReview,
  op: PendingDocumentReviewUpdateTask,
) => {
  review.tasks.forEach((t) => {
    if (t.publicUid === op.taskId) {
      t.title = op.title ?? t.title;
      t.status = op.status ?? t.status;
    }
  });
};

export const applyPendingDestroyTask = (
  review: DocumentReview,
  op: PendingDocumentReviewDestroyTask,
) => {
  review.tasks.forEach((t) => {
    if (t.publicUid === op.taskId) {
      t._deleting = true;
    }
  });
};

export const findComment = (
  review: DocumentReview,
  id: string,
): Comment | undefined => {
  const comments = review.comments.flatMap((c) =>
    c.publicUid === id
      ? [c]
      : c.children?.flatMap((ch) => (ch.publicUid === id ? [ch] : [])) ?? [],
  );
  if (comments.length > 0) {
    return comments[0];
  }
};

///
/// Pending Tests
///

export const isReviewCommentPending = (
  comment: Comment,
  review: DocumentReview,
) => {
  return (review?._pending ?? []).some(
    (op) =>
      op.mutationId === comment.publicUid || op.commentId === comment.publicUid,
  );
};

export const isReviewTaskPending = (
  task: ReviewTask,
  review: DocumentReview,
) => {
  return (review?._pending ?? []).some(
    (op) => op.mutationId === task.publicUid || op.taskId === task.publicUid,
  );
};

///
/// Utility
///

type ApplyDocRevMutFn = (
  _mutationId: string,
) => PendingDocumentReviewOperation[];

export const getDocReviewPending = (
  detail: DocumentReview | DocumentReviewListing,
) => detail._pending ?? [];

export const setDocReviewPending = (
  detail: DocumentReview | DocumentReviewListing,
  pending: PendingDocumentReviewOperation[],
) => {
  detail._pending = pending;
};

export const beginDocReviewMutation = (
  queryClient: QueryClient,
  id: string,
  fn: ApplyDocRevMutFn,
) => {
  return beginQueryClientMutation<
    PendingDocumentReviewOperation,
    DocumentReviewApiDetail,
    DocumentReviewsApiIndex,
    DocumentReview,
    DocumentReviewListing
  >(
    queryClient,
    id,
    fn,
    (d) => d.publicUid,
    getDocReviewPending,
    setDocReviewPending,
    DOC_REVIEW_KEYS,
  );
};

export const resolveDocReviewMutation = (
  queryClient: QueryClient,
  id: string,
  data: DocumentReviewApiDetail | undefined,
  context: { mutationId: string } | undefined,
) => {
  return resolveQueryClientMutation<
    PendingDocumentReviewOperation,
    DocumentReviewApiDetail,
    DocumentReviewsApiIndex,
    DocumentReview,
    DocumentReviewListing
  >(
    queryClient,
    id,
    data?.documentReview,
    context,
    (d) => d.publicUid,
    getDocReviewPending,
    setDocReviewPending,
    DOC_REVIEW_KEYS,
  );
};
