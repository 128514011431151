const DISALLOWED_NAME_SEQUENCES = ["http", "www", "asdf"];

import MailChecker from "mailchecker";

export const validateSignupData = (
  name: string,
  email: string,
  fax?: string,
) => {
  if (fax) {
    return "Please do not provide a fax number. We're hunting bots.";
  }

  if (DISALLOWED_NAME_SEQUENCES.some((seq) => name.includes(seq))) {
    return `The name "${name}" includes unexpected values. Are you sure you've input it correctly?`;
  }

  if (!MailChecker.isValid(email)) {
    return `"${email}" is from a disallowed domain. Please use a different email address.`;
  }
};

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (email: any): boolean =>
  EMAIL_REGEXP.test(String(email).toLowerCase());

export const isUrl = (url: any): boolean => {
  try {
    const parsed = new URL(url);
    return parsed.protocol === "http:" || parsed.protocol === "https:";
  } catch (_) {
    return false;
  }
};
