import { useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useEffect, useMemo } from "react";
import { Feature } from "src/types";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import { AppRoutes } from "../appRoutes";
import FeatureEnum from "../feature";
import { useRedirect } from "../redirect";

const getFeatures = (conf: AxiosRequestConfig) =>
  api.get<{ features: Feature[] }>(ApiRoutes.features(), { ...conf });

export const useFeatures = () => {
  return useQuery({
    queryKey: ["features"],
    queryFn: ({ signal }) => getFeatures({ signal }),
  });
};

export const useFeature = (featureName: FeatureEnum | undefined) => {
  const { data, isLoading } = useFeatures();

  const enabled = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const feature = data?.data?.features?.find((f) => f.name === featureName);

    if (feature) {
      return feature.enabled;
    }

    return false;
  }, [isLoading, data]);

  if (!featureName) {
    return { enabled: true, isLoading: false };
  }

  return { enabled, isLoading };
};

export const useFeatureEnabled = (
  featureName: FeatureEnum | undefined,
): boolean | undefined => {
  const { enabled, isLoading } = useFeature(featureName);

  if (isLoading) {
    return undefined;
  }

  return enabled;
};

export const useFeatureGate = (
  featureName: FeatureEnum,
  redirectTo: string = AppRoutes.root(),
) => {
  const { enabled, isLoading } = useFeature(featureName);
  const redirect = useRedirect();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isLoading && !enabled) {
      redirect(redirectTo);
    }
  }, [isLoading, enabled]);
};
