import { Alert, AlertProps, formatDate, parseDate } from "@ream/ui";
import clsx from "clsx";
import { differenceInDays } from "date-fns";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslations } from "src/state/TranslationsContext";
import { ExpiredTrialSubscription, Org, TrialSubscription } from "src/types";

type TrialAlertProps = {
  org: Org;
  className?: string;
} & AlertProps;

const ExpiringTrialAlert: React.FC<
  { subscription: TrialSubscription; className?: string } & AlertProps
> = ({ subscription, className, ...rest }) => {
  const { t } = useTranslations();

  const trialEndDate = parseDate(subscription.trialEndsAt);
  const subscriptionStartDate = parseDate(subscription.startedAt);

  const now = new Date();
  const daysUntilExpiration = differenceInDays(trialEndDate, now);
  const daysInTrial = Math.max(
    differenceInDays(trialEndDate, subscriptionStartDate),
    1,
  );

  const trialElapsed =
    ((daysInTrial - daysUntilExpiration) / daysInTrial) * 100;

  return (
    <Alert className={clsx("small p-2", className)} {...rest}>
      <p>{t("client.alerts.trial_alert", { days: daysUntilExpiration })}</p>

      <ProgressBar now={Math.round(trialElapsed)} striped />
    </Alert>
  );
};

const ExpiredTrialAlert: React.FC<
  { subscription: ExpiredTrialSubscription } & AlertProps
> = ({ subscription, className, ...rest }) => {
  const { t } = useTranslations();

  return (
    <Alert className={clsx("small p-2", className)} {...rest}>
      <p>
        {t("client.alerts.expired_trial_alert", {
          date: formatDate(subscription.trialEndsAt),
        })}
      </p>
    </Alert>
  );
};

export const TrialAlert: React.FC<TrialAlertProps> = ({ org, ...rest }) => {
  if (!org || (!org.subscription?.expiring && !org.subscription?.expired)) {
    return null;
  }

  if (org.subscription.expiring) {
    return <ExpiringTrialAlert subscription={org.subscription} {...rest} />;
  } else if (org.subscription.expired) {
    return <ExpiredTrialAlert subscription={org.subscription} {...rest} />;
  }

  return null;
};
