import {
  camelCase,
  clone,
  isArray,
  isNil,
  isObject,
  snakeCase,
  transform,
} from "lodash-es";
import { customAlphabet } from "nanoid";

const base36 = customAlphabet("123456789abcdefghijklmnopqrstuvwxyz", 21);
const randomLetter = customAlphabet("abcdefghijkmnopqrstuvwxyz", 1);

// Mirror implementation of TokenGenerator.generate in token_generator.rb
// on the server side.
export const generateToken = ({
  prefix,
  length = 10,
}: {
  prefix?: string;
  length?: number;
}) => {
  let token = base36(length);
  let isnum = /^\d+$/.test(token);

  if (isnum) {
    token = `${randomLetter(1)}${token.slice(0, -1)}`;
  }

  return prefix ? `${prefix}${token}` : token;
};

const recaseObject = (obj: Record<string, any>, tx: any): Record<string, any> =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : tx(key);

    acc[camelKey] = isObject(value) ? recaseObject(value, tx) : value;
  });

export const camelize = (obj: Record<string, any>): Record<string, any> =>
  recaseObject(obj, camelCase);

export const snakify = (obj: Record<string, any>): Record<string, any> =>
  recaseObject(obj, snakeCase);

export const exhaustiveGuard = (_value: never): never => {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(
      _value,
    )}`,
  );
};

export const elideEmail = (email: string, length: number = 100) => {
  if (email.length <= length) {
    return email;
  }

  const parts = email.split("@", 2);
  let username = parts[0];
  let domain = parts[1];

  if (!domain) {
    return `${username.substring(0, length - 1)}…`;
  }

  if (username && username.length > length / 2) {
    username = `${username.substring(0, length / 2)}…`;
  }

  if (domain && domain.length > length / 2) {
    domain = `…${domain.substring(domain.length - length / 2)}`;
  }

  return `${username}@${domain}`;
};

export const arrayMove = <T>(arr: T[], oldIdx: number, newIdx: number): T[] => {
  let newArr = clone(arr);
  newArr.splice(newIdx, 0, ...newArr.splice(oldIdx, 1));
  return newArr;
};

export const isNotNil = <T>(argument: T | undefined | null): argument is T => {
  return !isNil(argument);
};

export const objMove = <
  T extends Record<P, unknown>,
  P extends string | number | symbol,
>(
  obj: T,
  oldKey: P,
  newKey: P,
): T => {
  if (oldKey === newKey) {
    return obj;
  }

  if (!(oldKey in obj)) {
    return obj;
  }

  if (newKey in obj) {
    return obj;
  }

  delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];

  return obj;
};
