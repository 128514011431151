import React, { PropsWithChildren, useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";

import { Button, DomPortal } from "@ream/ui";

import { Icon } from "@ream/ui";
import {
  BadgeCheckIcon,
  Building2Icon,
  CopyIcon,
  ExternalLinkIcon,
  FileStackIcon,
  FileTextIcon,
  LogOutIcon,
  LucideIcon,
  SettingsIcon,
  SwordsIcon,
  UserIcon,
  UsersIcon,
  XIcon,
} from "lucide-react";
import { ActiveLink } from "src/components/ActiveLink";
import { UserAvatar } from "src/components/Avatar";
import { TrialAlert } from "src/components/TrialAlert";
import { useAuth } from "src/state/AuthContext";
import { NamedIcons } from "src/util/NamedIcons";
import { useFeatureEnabled } from "src/util/api/features";
import { useOrg } from "src/util/api/orgsApi";
import { AppRoutes } from "src/util/appRoutes";
import Feature from "src/util/feature";
import { isAdminAccount, isGlobalAdmin } from "src/util/permissions";
import { GlobalAdminNav } from "./GlobalAdminNav";

type NavItem = {
  id?: string;
  href: string;
  label: string;
  Icon: LucideIcon;
  contact?: boolean;
  matchSubroutes?: boolean;
  feature?: Feature;
};

const NAV_ITEMS: NavItem[] = [
  {
    id: "documents_nav_item",
    href: AppRoutes.documents(),
    label: "Documents",
    Icon: FileTextIcon,
  },
  {
    id: "templates_nav_item",
    href: AppRoutes.templates(),
    label: "Templates",
    Icon: CopyIcon,
  },
  {
    id: "companies_nav_item",
    href: AppRoutes.companies(),
    label: "Companies",
    Icon: Building2Icon,
    matchSubroutes: true,
  },
  {
    id: "contacts_nav_item",
    href: AppRoutes.contacts(),
    label: "People",
    Icon: UserIcon,
    matchSubroutes: true,
  },
  {
    id: "campaigns_nav_item",
    href: AppRoutes.campaigns(),
    label: "Campaigns",
    Icon: FileStackIcon,
    matchSubroutes: true,
    feature: Feature.CAMPAIGNS,
  },
  {
    id: "reviews_nav_item",
    href: AppRoutes.reviews(),
    label: "Reviews",
    Icon: BadgeCheckIcon,
    matchSubroutes: true,
    feature: Feature.DOCUMENT_REVIEWS,
  },
  {
    id: "negotiations_nav_item",
    href: AppRoutes.negotiations(),
    label: "Negotiations",
    Icon: SwordsIcon,
    matchSubroutes: true,
    feature: Feature.DOCUMENT_REVIEWS,
  },
  {
    id: "signatures_nav_item",
    href: AppRoutes.signatures(),
    label: "Signature Requests",
    Icon: NamedIcons.Sign,
    matchSubroutes: true,
    feature: Feature.ESIGN,
  },

  {
    id: "signatures_nav_item",
    href: AppRoutes.signatures(),
    label: "My Signatures",
    Icon: NamedIcons.Sign,
    matchSubroutes: true,
    feature: Feature.ESIGN,
    contact: true,
  },
  {
    id: "negotiations_nav_item",
    href: AppRoutes.negotiations(),
    label: "My Negotiations",
    Icon: SwordsIcon,
    matchSubroutes: true,
    feature: Feature.DOCUMENT_REVIEWS,
    contact: true,
  },
];

const SidebarNavLink: React.FC<NavItem> = ({
  href,
  id,
  Icon: NavIcon,
  label,
  matchSubroutes = false,
  feature,
  contact,
}) => {
  const enabled = useFeatureEnabled(feature);
  const { user } = useAuth();

  if (!enabled) {
    return null;
  }

  if (!user?.role) {
    return null;
  }

  if (contact && user.role != "contact") {
    return null;
  }

  if (!contact && user.role == "contact") {
    return null;
  }

  // if (!contact && (!user?.role || user.role == "contact")) {
  //   return null;
  // }

  return (
    <ActiveLink
      href={href}
      passHref
      legacyBehavior
      matchSubroutes={matchSubroutes}
    >
      <Nav.Link
        id={id}
        className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
      >
        <Icon color="primary" icon={NavIcon} />
        {label}
      </Nav.Link>
    </ActiveLink>
  );
};

export const SidebarPortal: React.FC<PropsWithChildren> = ({ children }) => {
  return <DomPortal id="#sidebar_portal">{children}</DomPortal>;
};

export const SidebarNav: React.FC<{ hideLogo?: boolean }> = ({
  hideLogo = false,
}) => {
  const { logout, isAuthenticated, user } = useAuth();
  const { data: { org } = { org: null } } = useOrg();

  return (
    <div className="d-flex h-100 flex-column">
      {!hideLogo && (
        <div className="mb-3">
          <h1 className="logotype">Ream.</h1>
        </div>
      )}

      <Nav variant="pills" className="sidebar_nav flex-column">
        {NAV_ITEMS.map((i) => (
          <SidebarNavLink key={i.label} {...i} />
        ))}
      </Nav>

      <Nav variant="pills" className="sidebar_nav flex-column mt-auto">
        <div id="sidebar_portal" />

        {user?.role === "contact" && (
          <Button
            block
            href="https://reamdocs.com"
            target="_blank"
            StartIcon={ExternalLinkIcon}
          >
            Learn About Ream
          </Button>
        )}

        {isGlobalAdmin(user) && (
          <>
            <hr />
            <GlobalAdminNav />
            <hr />
          </>
        )}

        {org && <TrialAlert org={org} className="outdent-2 mb-3" />}

        {isAdminAccount(user) && (
          <ActiveLink href={AppRoutes.orgSettings()} passHref legacyBehavior>
            <Nav.Link className="sidebar_nav_link d-flex flex-row align-items-center gap-2">
              <Icon color="primary" icon={SettingsIcon} />
              Org Settings
            </Nav.Link>
          </ActiveLink>
        )}

        {user?.role !== "contact" && (
          <ActiveLink
            href={AppRoutes.profileSettings()}
            passHref
            legacyBehavior
          >
            <Nav.Link className="sidebar_nav_link d-flex flex-row align-items-center gap-2">
              <Icon color="primary" icon={UsersIcon} />
              Profile Settings
            </Nav.Link>
          </ActiveLink>
        )}

        {isAuthenticated && (
          <>
            {user && (
              <UserAvatar user={user} showName size={18} className="ms-1" />
            )}
            <Nav.Link
              onClick={() => logout()}
              className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
            >
              <Icon color="primary" icon={LogOutIcon} /> Log Out
            </Nav.Link>
          </>
        )}
      </Nav>
    </div>
  );
};

export const DrawerSidebarNav: React.FC<{ trigger: React.ReactElement }> = ({
  trigger,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const triggerComponent = React.cloneElement(trigger, {
    onClick: handleShow,
  });

  return (
    <>
      {triggerComponent}

      <Offcanvas show={show} onHide={handleClose} className="drawer_nav">
        <Offcanvas.Header className="d-flex align-items-center flex-row">
          <h1 className="logotype">Ream.</h1>

          <Button
            StartIcon={XIcon}
            variant="icon"
            className="ms-auto"
            onClick={handleClose}
            iconOnly
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarNav hideLogo />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
