import { trailingSlashIt } from "@ream/utils";
import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import React, { ReactElement, useMemo } from "react";

export const ActiveLink: React.FC<
  LinkProps & {
    children: React.ReactNode;
    matchSubroutes?: boolean;
    excludedSubroutes?: string[];
  }
> = ({
  children,
  href,
  matchSubroutes = false,
  excludedSubroutes = ["/"],
  ...props
}) => {
  const pathname = usePathname();

  const isMatch = useMemo(() => {
    if (!pathname) {
      return false;
    }

    const slashed = trailingSlashIt(pathname);
    if (matchSubroutes) {
      return (
        !excludedSubroutes.includes(href.toString()) &&
        slashed.startsWith(href.toString())
      );
    } else {
      return slashed === trailingSlashIt(href.toString());
    }
  }, [pathname, href, matchSubroutes, excludedSubroutes]);

  return (
    <Link href={href} {...props}>
      {isMatch
        ? React.cloneElement(children as ReactElement, { active: true })
        : children}
    </Link>
  );
};
