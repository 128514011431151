import { QueryClient } from "@tanstack/react-query";
import { assign, cloneDeep, noop } from "lodash-es";
import { PendingOperation } from "src/types";
import { ApiResponse } from "./types";

export const entityKeys = <IndexParams>(baseKey: string) => {
  const keys = {
    all: [baseKey] as const,
    lists: () => [...keys.all, "list"] as const,
    list: (params: IndexParams) => [...keys.lists(), { params }] as const,
    details: () => [...keys.all, "detail"] as const,
    detail: (id: string, params?: any) =>
      [...keys.details(), id, params] as const,
  };

  return keys;
};

export type CacheKeyLibrary = {
  baseKey: string;
  detailKey: string;
  collectionKey: string;
};

/**
 * Update the document review cache (both list and detail views) for the given id with the given newData.
 *
 * @param id The id of the object to update. This will be used to update both the list and detail views
 * @param newData The detail data of the object. This *must* extend the summary type, so that the detail
 * data can replace the summary data without breaking any summary views. If this is undefined, the old
 * object's value will be maintained. This can be used to modify the pending operations list using `setPending`.
 * @param getId A function that can return the id from a detail or summary
 * @param getPending A function that can return the pending list from an object
 * @param setPending A function that can write the pending list of operations to an object. This function can be updated to mutate an object
 * @param queryClient The react-query queryClient
 * @param cacheKeys An CacheKeyLibrary object defining the important keys for the entity.
 */

type PendingNoop = PendingOperation;

export const updateCache = <
  Op extends PendingOperation,
  ApiDetail extends ApiResponse<Record<string, R>>,
  ApiCollection extends ApiResponse<Record<string, RC[]>>,
  R extends RC,
  RC,
>(
  id: string,
  newData: R | RC | undefined,
  getId: (data: R | RC) => string,
  getPending: (detail: R | RC) => Op[],
  setPending: (detail: R | RC, pending: Op[]) => void,
  queryClient: QueryClient,
  cacheKeys: CacheKeyLibrary,
) => {
  const { baseKey, detailKey, collectionKey } = cacheKeys;
  const keys = entityKeys(baseKey);

  queryClient.setQueriesData(
    { queryKey: keys.lists(), exact: false },
    (old: ApiCollection | undefined) => {
      const newCollection = cloneDeep(old);

      if (!newCollection) {
        return undefined;
      }

      // @ts-expect-error: The typing here is hard; see https://www.totaltypescript.com/concepts/type-string-cannot-be-used-to-index-type
      newCollection[collectionKey] = old?.[collectionKey]?.map((p: RC) => {
        if (getId(p) !== id) {
          return p;
        }

        const baseData: RC | undefined = (newData as unknown as RC) ?? p;
        const cloned = cloneDeep(baseData);
        const pending = cloneDeep(getPending(p));
        setPending(cloned, pending);
        return assign({}, p, cloned);
      });

      return newCollection;
    },
  );

  queryClient.setQueriesData(
    { queryKey: keys.detail(id), exact: true },
    (old: ApiDetail | undefined) => {
      if (!old && !newData) {
        return undefined;
      }

      const newDetail = cloneDeep(old) ?? ({} as ApiDetail);

      const baseData = newData ?? old!![detailKey];
      const cloned = cloneDeep(baseData);
      const detail = old?.[detailKey] as R;
      const pending = cloneDeep(detail ? getPending(detail) : []);
      setPending(cloned, pending);

      // @ts-expect-error: The typing here is hard; see https://www.totaltypescript.com/concepts/type-string-cannot-be-used-to-index-type
      newDetail[detailKey] = cloned;

      return assign({}, old, newDetail);
    },
  );
};

// Same as above, but without any pending operations
export const simpleUpdateCache = <
  ApiDetail extends ApiResponse<Record<string, R>>,
  ApiCollection extends ApiResponse<Record<string, RC[]>>,
  R extends RC,
  RC,
>(
  id: string,
  newData: R | RC | undefined,
  getId: (data: R | RC) => string,
  queryClient: QueryClient,
  cacheKeys: CacheKeyLibrary,
) => {
  return updateCache<PendingNoop, ApiDetail, ApiCollection, R, RC>(
    id,
    newData,
    getId,
    () => [],
    noop,
    queryClient,
    cacheKeys,
  );
};

export const clearCache = <ApiDetail>(
  id: string,
  getId: (_data: ApiDetail) => string,
  queryClient: QueryClient,
  baseKey: string,
) => {
  const keys = entityKeys(baseKey);

  queryClient.setQueriesData(
    { queryKey: keys.lists(), exact: false },
    (old: ApiDetail[] | undefined) => {
      return old?.filter((p) => getId(p) !== id);
    },
  );

  queryClient.removeQueries({ queryKey: keys.detail(id), exact: false });
};
