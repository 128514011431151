import { Icon } from "@ream/ui";
import {
  BugIcon,
  ExternalLinkIcon,
  MailboxIcon,
  ShieldPlusIcon,
  VenetianMaskIcon,
} from "lucide-react";
import { Nav } from "react-bootstrap";
import { useAuth } from "src/state/AuthContext";
import { apiUrl } from "src/util/api";

const ExternalIndicator = () => (
  <Icon
    color="muted"
    icon={ExternalLinkIcon}
    size="sm"
    className="opacity-50"
  />
);

export const GlobalAdminNav = () => {
  const { startImpersonatingUser } = useAuth();

  return (
    <>
      <Nav.Link
        href={apiUrl("/admin/")}
        target="_blank"
        active={false}
        className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
      >
        <Icon color="primary" icon={ShieldPlusIcon} />
        Internal Admin
        <ExternalIndicator />
      </Nav.Link>

      <Nav.Link
        onClick={startImpersonatingUser}
        active={false}
        className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
      >
        <Icon color="primary" icon={VenetianMaskIcon} />
        Impersonate
      </Nav.Link>

      {process.env.NODE_ENV === "development" && (
        <Nav.Link
          href={apiUrl("/__better_errors")}
          target="_blank"
          active={false}
          className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
        >
          <Icon color="primary" icon={BugIcon} />
          Better Errors
          <ExternalIndicator />
        </Nav.Link>
      )}

      {process.env.NODE_ENV === "development" && (
        <Nav.Link
          href={apiUrl("/_dev/letter_opener")}
          target="_blank"
          active={false}
          className="sidebar_nav_link d-flex flex-row align-items-center gap-2"
        >
          <Icon color="primary" icon={MailboxIcon} />
          Letter Opener
          <ExternalIndicator />
        </Nav.Link>
      )}
    </>
  );
};
