import { Icon } from "@ream/ui";
import BoringAvatar, { AvatarProps } from "boring-avatars";
import clsx from "clsx";
import { UserIcon } from "lucide-react";
import React from "react";
import { Image, Stack } from "react-bootstrap";
import { FileValue, PersonType } from "src/types";

type AvatarUser = {
  fullName: string;
  avatar?: FileValue;
  userPalette?: string[];
  personType?: PersonType;
};

type CommonAvatarProps = {
  size?: number;
  className?: string;
};

export const UserAvatar: React.FC<
  { user: AvatarUser; showName?: boolean } & CommonAvatarProps
> = ({ user, showName = false, className, ...rest }) => {
  const avatar =
    user.personType === "external" ? (
      <Icon icon={UserIcon} />
    ) : user.avatar?.url ? (
      <ImageAvatar
        email={user.fullName}
        src={user.avatar.url}
        className={showName ? undefined : className}
        {...rest}
      />
    ) : (
      <Avatar
        email={user.fullName}
        colors={user.userPalette}
        className={showName ? undefined : className}
        {...rest}
      />
    );

  return showName ? (
    <Stack
      className={clsx(className, "align-items-center")}
      direction="horizontal"
      gap={2}
    >
      {avatar} <span>{user.fullName}</span>
    </Stack>
  ) : (
    avatar
  );
};

const ImageAvatar: React.FC<
  {
    email: string;
    src: string;
  } & CommonAvatarProps
> = ({ email, src, size = 32, className }) => {
  return (
    <span style={{ lineHeight: 1 }} className={className} key={email}>
      <Image src={src} roundedCircle width={size} alt={email} />
    </span>
  );
};

const Avatar: React.FC<
  {
    email: string;
    colors?: string[];
    className?: string;
  } & AvatarProps &
    CommonAvatarProps
> = ({ email, colors, className, ...props }) => {
  return (
    <span style={{ lineHeight: 1 }} className={className} key={email}>
      <BoringAvatar
        size={32}
        name={email}
        variant="beam"
        colors={colors}
        {...props}
      />
    </span>
  );
};
