import {
  QueryOptions,
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  create as createBatcher,
  keyResolver,
  windowScheduler,
} from "@yornaath/batshit";
import { AxiosRequestConfig } from "axios";
import { useEffect, useMemo } from "react";
import { Agentable, AgentableId, Org, UserRole } from "src/types";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import {
  ApiResponse,
  PagedQueryConf,
  SearchQueryConf,
  SortableQueryConf,
  UpdateParams,
} from "./types";

type UpdateOrgParams = UpdateParams<Org>;
type InviteUserParams = {
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
};
type UpdateMembershipParams = { id: string; role: UserRole };

export type GetOrgPeopleQueryParams = PagedQueryConf &
  SearchQueryConf &
  SortableQueryConf;

export type OrgApiDetail = ApiResponse<{ org: Org }>;
export type PeopleApiIndex = ApiResponse<{ people: Agentable[] }>;

const getOrg = async (conf: AxiosRequestConfig) => {
  const resp = await api.get<OrgApiDetail>(ApiRoutes.org(), conf);

  return resp.data;
};

const getOrgPeople = async (
  params: GetOrgPeopleQueryParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<PeopleApiIndex>("/api/org/people", {
    params,
    ...conf,
  });
  return resp.data;
};

const getOrgPeopleBatched = async (
  ids: AgentableId[],
  conf: AxiosRequestConfig = {},
) => {
  const resp = await api.get<PeopleApiIndex>("/api/org/people", {
    params: {
      ids: ids.join(","),
    },
    ...conf,
  });
  return resp.data;
};

const updateOrg = async ({ data }: UpdateOrgParams) => {
  const resp = await api.patch<OrgApiDetail>(ApiRoutes.org(), data);
  return resp.data;
};

const inviteUser = async (data: InviteUserParams) => {
  const resp = await api.post<void>(ApiRoutes.orgMemberships(), data);
  return resp.data;
};

const updateMembership = async ({ id, ...data }: UpdateMembershipParams) => {
  const resp = await api.patch(ApiRoutes.orgMembership({ id }), data);
  return resp.data;
};

const deactivateMembership = async (id: string) => {
  const resp = await api.delete(ApiRoutes.orgMembership({ id }));

  return resp.data;
};

export const useInviteUser = (): UseMutationResult<
  void,
  unknown,
  InviteUserParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inviteUser,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["org"],
      }),
  });
};

export const useUpdateMembership = (): UseMutationResult<
  void,
  unknown,
  UpdateMembershipParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMembership,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["org"],
      }),
  });
};

export const useDeactivateMembership = (): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deactivateMembership,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["org"],
      }),
  });
};

export const useUpdateOrg = (): UseMutationResult<
  OrgApiDetail,
  unknown,
  UpdateOrgParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: UpdateOrgParams) => updateOrg(p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["org"],
      });
    },
  });
};

const peopleBatcher = createBatcher<Agentable[], AgentableId, Agentable>({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (ids: AgentableId[]) => {
    const res = await getOrgPeopleBatched(ids);
    return res.people;
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: keyResolver<Agentable[], AgentableId, Agentable>("publicUid"),
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const useOrgPeopleSearch = (
  params: GetOrgPeopleQueryParams = {},
  options: Partial<UseQueryOptions<PeopleApiIndex>> = {},
) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["org", "people"],
    queryFn: ({ signal }) => getOrgPeople(params, { signal }),
    ...options,
  });

  useEffect(() => {
    if (query.data) {
      query.data.people.forEach((p) => {
        queryClient.setQueryData(["org", "agentable", p.publicUid], p);
      });
    }
  }, [query.data, queryClient]);

  return { data: query.data, query };
};

export const useOrgPeople = (
  ids: AgentableId[],
  queriesOptions: QueryOptions<Agentable> & { enabled?: boolean } = {},
) => {
  const queries = useQueries({
    queries: ids.map((id) => ({
      queryKey: ["org", "agentable", id],
      queryFn: () => peopleBatcher.fetch(id),
      ...queriesOptions,
    })),
  });

  const results = queries.map((q) => q.data).filter((x) => x) as Agentable[];
  const isLoading = useMemo(() => queries.some((q) => q.isLoading), [queries]);

  return { data: results, queries, isLoading };
};

export const useOrgPerson = (id: AgentableId) => {
  const { data, queries } = useOrgPeople([id]);

  return { data: data.length > 0 ? data[0] : undefined, query: queries[0] };
};

export const useOrg = (
  options: Partial<UseQueryOptions<OrgApiDetail>> = {},
) => {
  const query = useQuery({
    queryKey: ["org"],
    queryFn: ({ signal }) => getOrg({ signal }),
    ...options,
  });

  const update = useUpdateOrg();
  const invite = useInviteUser();

  return { data: query.data, query, update, invite };
};
