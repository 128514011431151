import Link from "@tiptap/extension-link";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Editor, getAttributes } from "@tiptap/react";

export const processToggleLink = (editor: Editor) => {
  if (editor.isActive("link")) {
    return processRemoveLink(editor);
  } else {
    return processEditLink(editor);
  }
};

export const processRemoveLink = (editor: Editor) => {
  editor.chain().focus().unsetLink().run();
  return true;
};

export const processEditLink = (editor: Editor) => {
  const previousUrl = editor.getAttributes("link").href;
  const url = window.prompt("Link URL", previousUrl);

  // cancelled
  if (url === null) {
    return true;
  }

  // empty
  if (url === "") {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();

    return true;
  }

  // update link
  editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  return true;
};

// 2. Overwrite the keyboard shortcuts
export const EditableLink = Link.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      openOnClick: false,
      openOnModClick: true,
    };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-k": ({ editor }) => processToggleLink(editor as Editor),
    };
  },
  addProseMirrorPlugins() {
    const plugins: Plugin[] = this.parent?.() || [];

    const ctrlClickHandler = new Plugin({
      key: new PluginKey("handleControlClick"),
      props: {
        handleClick(view, pos, event) {
          const attrs = getAttributes(view.state, "link");
          const link = (event.target as HTMLElement)?.closest("a");

          const keyPressed = event.ctrlKey || event.metaKey;

          if (keyPressed && link && attrs.href) {
            window.open(attrs.href, attrs.target);

            return true;
          }

          return false;
        },
      },
    });

    plugins.push(ctrlClickHandler);

    return plugins;
  },
});
