import HardBreak from "@tiptap/extension-hard-break";
import { isFunction } from "@tiptap/react";

export const CustomHardBreak = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      // ↓ your new keyboard shortcut
      "Shift-Enter": () => this.editor.commands.setHardBreak(),
      "Mod-Enter": () => {
        if (isFunction(this.editor.chain().insertFn)) {
          return this.editor.chain().insertFn("{%pagebreak%}").run();
        }

        return false;
      },
    };
  },
});
