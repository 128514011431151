import { NodeType } from "@tiptap/pm/model";
import { Command, Range } from "@tiptap/react";

export const makeInsertFn =
  (type: NodeType, startRegex: RegExp, endRegex: RegExp) =>
  (text: string, range?: Range): Command =>
  ({ editor, tr, dispatch }) => {
    if (dispatch) {
      text = text.trim().replace(startRegex, "").replace(endRegex, "");

      range ||= { from: tr.selection.from, to: tr.selection.to };

      const nodeAfter = editor.view.state.selection.$to.nodeAfter;
      const overrideSpace = nodeAfter?.text?.startsWith(" ");

      if (overrideSpace) {
        range.to += 1;
      }

      tr.replaceRangeWith(
        range.from,
        range.to,
        type.create({ id: text, label: text }),
      ).scrollIntoView();

      const selection = window.getSelection();

      if (selection && !selection.isCollapsed) {
        selection.collapseToEnd();
      }
    }

    return true;
  };
