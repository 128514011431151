import { LoadingGate } from "@ream/ui";

type Props = {
  title?: string;
  loading?: boolean;
  children: React.ReactNode;
};

export const BasePageLayout: React.FC<Props> = ({
  title,
  loading = false,
  children,
}) => {
  return (
    <>
      <title>{title ? `${title} | Ream` : `Ream`}</title>

      <LoadingGate loading={loading}>{children}</LoadingGate>
    </>
  );
};
