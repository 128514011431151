import {
  BadgeCheckIcon,
  CircleSlashIcon,
  EyeIcon,
  PenToolIcon,
  RedoDotIcon,
} from "lucide-react";

export const NamedIcons = {
  Preview: EyeIcon,
  Executed: BadgeCheckIcon,
  ResendInvitation: RedoDotIcon,
  SignatureDecline: CircleSlashIcon,
  Sign: PenToolIcon,
};
