import CharacterCount from "@tiptap/extension-character-count";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { CustomHardBreak } from "./extensions/CustomHardBreak";
import { EditableLink } from "./extensions/EditableLink";
import { MentionFnBase } from "./extensions/MentionFnBase";
import { MentionVarBase } from "./extensions/MentionVarBase";

import { AnyExtension, Extensions } from "@tiptap/core";

const baseExtensions: Extensions = [
  StarterKit.configure({
    hardBreak: false,
    history: false,
  }),
  CustomHardBreak,
  Underline,
  CharacterCount,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Table.configure({
    HTMLAttributes: {
      class: "table",
    },
  }),
  TableCell,
  Highlight,
  TableHeader,
  TableRow,
  EditableLink.configure({
    protocols: ["mailto"],
  }),

  MentionVarBase,
  MentionFnBase,
];

type ExtensionReplacement = { replace: AnyExtension; with: AnyExtension };
type GetExtensionsArgs = {
  replacements?: ExtensionReplacement[];
  extras?: Extensions;
};
export const getExtensions = ({
  replacements = [],
  extras = [],
}: GetExtensionsArgs = {}) => {
  const replacedExts = baseExtensions.map((e) => {
    const replacement = replacements.find((r) => r.replace === e);
    if (!replacement) {
      return e;
    }
    return replacement.with;
  });

  return replacedExts.concat(...extras);
};
