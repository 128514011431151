import { QueryClient } from "@tanstack/react-query";
import { cloneDeep, uniqueId } from "lodash-es";
import { PendingOperation } from "src/types";
import { CacheKeyLibrary, updateCache } from "../api/apiCache";
import { ApiResponse } from "../api/types";

export const applyPendingOperations = <Op extends PendingOperation, Detail>(
  detail: Detail | undefined,
  ops: Op[],
  applyFn: (_detail: Detail, _op: Op) => void,
) => {
  if (!detail) {
    return detail;
  }
  const localDetail = cloneDeep(detail);

  ops.forEach((op) => applyFn(localDetail, op));

  return localDetail;
};

type QueryClientMutationFn<Op> = (_mutationId: string) => Op[];
/**
 * Adds new pending operations to the resource identified by `queryClient` and
 * `key`, and returns a context that includes the mutation identifier. This context can be returned by the
 * `onMutation` handler, and then used in `onSuccess` and `onError` handlers to pass to `resolveQueryClientMutation`
 *
 * @param queryClient The `react-query` client to update
 * @param key The `key` within the `queryClient` that identifies the resource to update.
 * @param fn A function that takes a `mutationId` and produces the array of `Operations` to add to the resource.
 * @param getPending A function that can read the `_pending` array for the resource
 * @param setPending A function that can write the `_pending` array for the resource
 *
 * @returns A context that can be returned in the `onMutation` handler, that can be used to identify these mutations
 * in future `onSuccess` and `onError` handlers.
 */
export const beginQueryClientMutation = <
  Op extends PendingOperation,
  ApiDetail extends ApiResponse<Record<string, R>>,
  ApiCollection extends ApiResponse<Record<string, RC[]>>,
  R extends RC,
  RC,
>(
  queryClient: QueryClient,
  id: string,
  operationsFn: QueryClientMutationFn<Op>,
  getId: (_detail: R | RC) => string,
  getPending: (_detail: R | RC) => Op[],
  setPending: (_detail: R | RC, _pending: Op[]) => void,
  cacheKeys: CacheKeyLibrary,
) => {
  const mutationId = uniqueId("api-mutation-");
  const mutationContext = { mutationId };

  // construct new pending operations
  const newPending = operationsFn(mutationId);
  const addNewPending = (obj, pending) => {
    setPending(obj, [...pending, ...newPending]);
  };

  // call update the cache with `undefined` to insert the new pending values
  updateCache<Op, ApiDetail, ApiCollection, R, RC>(
    id,
    undefined,
    getId,
    getPending,
    addNewPending,
    queryClient,
    cacheKeys,
  );

  return mutationContext;
};

/**
 * Removes the pending operations associated with the mutation in the `context` from the resource identified by `queryClient` and
 * `key`. This also applies new server authoritative `data` if it is available.
 *
 * @param queryClient The `react-query` client to update
 * @param key The `key` within the `queryClient` that identifies the resource to update.
 * @param data The server authoritative data to set, if available. If this is `undefined` the server authoritative data will not be updated, and thus the pending operation will be rolled back.
 * @param context The context generated by `beginQueryClientMutation` that gets passed into the `onSuccess` and `onError` handlers
 * @param getPending A function that can read the `_pending` array for the resource
 * @param setPending A function that can write the `_pending` array for the resource
 */
export const resolveQueryClientMutation = <
  Op extends PendingOperation,
  ApiDetail extends ApiResponse<Record<string, R>>,
  ApiCollection extends ApiResponse<Record<string, RC[]>>,
  R extends RC,
  RC,
>(
  queryClient: QueryClient,
  id: string,
  data: R | undefined,
  context: { mutationId: string } | undefined,
  getId: (_detail: R | RC) => string,
  getPending: (_detail: R | RC) => Op[],
  setPending: (_detail: R | RC, _pending: Op[]) => void,
  cacheKeys: CacheKeyLibrary,
) => {
  const removeMutationPending = (obj, pending) => {
    const updatedPending = pending.filter((l) => {
      l.mutationId != context?.mutationId;
    });
    setPending(obj, updatedPending);
  };

  // call update the cache with `undefined` to insert the new pending values
  updateCache<Op, ApiDetail, ApiCollection, R, RC>(
    id,
    data,
    getId,
    getPending,
    removeMutationPending,
    queryClient,
    cacheKeys,
  );
};
